
.my-button {
    background-color: #e08f1f;
    color: #000;
    border-color: #ba7719;
  }
  
.my-button:hover {
    background-color: #ba7719;
    color: #000;
    border-color: #ba7719;
}

.my-button:active:hover {
    background-color: #ba7719;
    color: #000;
    border-color: #ba7719;
}

.my-button:active {
    background-color: #ba7719;
    color: #000;
    border-color: #ba7719;
}

.toggle-button {
    background-color: #fbe6cc;
    color: #000;
    border-color: #ba7719;
}

.toggle-button:hover {
    background-color: #fbdab1;
    color: #000;
    border-color: #ba7719;
}

.toggle-button:active:hover {
    background-color: #fbdab1;
    color: #000;
    border-color: #ba7719;
}

.toggle-button:active {
    background-color: #fbdab1;
    color: #000;
    border-color: #ba7719;
}

.my-card {
    border-color: #ba7719;
    background-color: #fbe6cc;
}

.header {
    background-color: #f6d2a5;
    border-color: #ba7719;
}

.title {
    font-size: 4rem; 
    color: #ba7719;
    text-shadow:
    -0.5px -0.5px 0 black,
    0.5px -0.5px 0 black,
    -0.5px 0.5px 0 black,
    0.5px 0.5px 0 black; 
    display: inline;
}

.subtitle {
    font-size: 1.5rem;
    
}

.footer {
    font-size: 1.25rem;
}

.card-text {
    font-size: 1.5rem;
}

.header-card {
    padding: 0.4rem;
}

.input-error {
    color: "red";
}

.form-label {
    font-size: 1rem;
}

.my-form {
    width: 60%;
    margin: 0 auto;
}

.logo {
    max-height: 60px;
    max-width: 100%;
    margin-right: 5px;
}

.warning {
    font-size: 0.7rem;
}

@media (max-width: 1199px) {
    .my-form {
        width: 85%;
    }
}

@media (max-width: 767px) {
    .title {
      font-size: 2.25rem; 
    }
    .subtitle {
        font-size: 1rem;
    }
    .card-text {
        font-size: 1rem;
    }
    .footer {
        font-size: 0.9rem;
    }
    .form-label {
        font-size: 0.8rem;
    }
    .my-form {
        width: 100%;
    }
    .logo {
        max-height: 30px;
    }
    .warning {
        font-size: 0.6rem;
    }
}

@media (max-width: 300px) {
    .title {
        font-size: 1.8rem; 
        line-height: 1.2; 
    }
    .subtitle {
        font-size: 0.5rem;
    }
    .card-text {
        font-size: 0.8rem;
    }
    .footer {
        font-size: 0.65rem;
    }
    .form-label {
        font-size: 0.7rem;
    }
    .my-form {
        width: 100%;
    }
    .logo {
        max-height: 10px;
    }
    .warning {
        font-size: 0.3rem;
    }
}